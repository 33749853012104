<template>
    <div class="page-content">
        <slot name="content"></slot>
    </div>
</template>

<script>
export default {
    name: 'BasePageContent'
}
</script>
