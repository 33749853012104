<template>
  <div>
    <base-page-header title="W2 Commissions"> </base-page-header>

    <div class="page-tabs d-flex align-items-center justify-content-between">
      <ul class="tab-list">
        <li :class="{ active: ui.tab === 'Active' }" @click="setActiveTab('Active')">
          Active Commissions
        </li>
        <li :class="{ active: ui.tab === 'Processed' }" @click="setActiveTab('Processed')">
          Processed Commissions
        </li>
      </ul>
      <div class="search-wrapper m-0">
        <img src="@/assets/icons/icon-search.svg" alt="Icon search" />
        <input v-model="searchField" type="text" placeholder="Search" aria-label="search" @input="search"/>
      </div>
    </div>

    <base-page-content>
      <template #content>
        <page-spinner v-if="ui.loading" />
        <div class="table-block mt-2" v-if="!ui.loading">
          <table class="table">
            <thead>
              <tr>
                <th style="width: 5%">#</th>
                <th style="width: 10%" v-if="isGranted('ROLE_CEO_ASSISTANT')">Agent</th>
                <th style="width: 20%" v-if="!isGranted('ROLE_CEO_ASSISTANT')">Funding Request</th>
                <th style="width: 20%">Commission</th>
                <th style="width: 10%">Status</th>
                <th style="width: 20%">{{ ui.tab === 'Active' ? 'Submitted at' : 'Processed at' }}</th>
                <th style="width: 10%" v-if="isGranted('ROLE_CEO_ASSISTANT') && isActive()">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(comm, index) in commissions" :key="index">
                <td>{{ index + 1 }}</td>
                <td v-if="isGranted('ROLE_CEO_ASSISTANT')">{{ comm.agent }}</td>
                <td v-if="!isGranted('ROLE_CEO_ASSISTANT')">{{ comm.borrower }}</td>
                <td>{{ convertToCurrency(comm.amount) }}</td>
                <td>
                  <div :class="comm.status">{{ comm.status }}</div>
                </td>
                <td>{{ comm.submittedAt }}</td>
                <td v-if="isGranted('ROLE_CEO_ASSISTANT') && isActive()">
                  <div class="d-flex">
                    <button class="btn btn-primary" @click="process(comm.agentId)">Process</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
<!--        <Paging :paging="paging" :page="currentPage" @pageChanged="pagingUpdated" @limitChanged="pagingUpdated"/>-->
      </template>
    </base-page-content>
  </div>
</template>

<script>
import BasePageContent from "../../components/BasePageContent.vue";
import BasePageHeader from "../../components/BasePageHeader.vue";
import PageSpinner from "../../components/pageSpinner.vue";
// import Paging from "../../components/Paging";
import debounce from "@/utils/debounce";

export default {
  components: { BasePageHeader, BasePageContent, PageSpinner },
  name: "W2Commissions",
  data() {
    return {
      ui: {
        tab: "Active",
        loading: false,
      },
      paging: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      searchField: '',
      currentPage: 1,
      commissions: [],
      search: debounce(() => {
        this.paging.offset = 0
        this.getData()
      }, 350),
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    setActiveTab(tab) {
      this.ui.tab = tab;
      this.searchField = ''
      this.paging.offset = 0
      this.paging.limit = 10
      this.paging.total = 0
      this.currentPage = 1
      this.getData()
    },
    pagingUpdated(paging) {
      this.paging = paging;
      this.currentPage = paging.currentPage
      this.getData();
    },
    getData() {
      this.ui.loading = true;
      this.$http.get('/api/v1/w2-commissions', {
        params: {
          offset: this.paging.offset,
          limit: this.paging.limit,
          query: this.searchField,
          tab: this.ui.tab
        }
      })
        .then((response) => {
          this.commissions = response.data.commissions
          this.paging = response.data.paging
          this.ui.loading = false;
        })
        .catch()
    },
    process(agentId) {
      this.$http.post(`/api/v1/w2-commissions/process/${agentId}`)
        .then(() => {
          this.pushAlert('error', 'W2 Commission processed successfully')
          this.getData()
        })
        .catch(() => {
          this.pushAlert('error', 'Something went wrong')
        })
    },
    deleteRecord(agentId) {
      if (confirm('Delete all agent\'s W2 commissions?')) {
        this.$http.delete(`/api/v1/w2-commissions/delete/${agentId}`)
          .then(() => {
            this.pushAlert('error', 'W2 Commission deleted successfully')
            this.getData()
          })
          .catch(() => {
            this.pushAlert('error', 'Something went wrong')
          })
      }
    },
    isActive() {
      return this.ui.tab === 'Active'
    }
  },
};
</script>

<style lang="scss" scoped>
.table-block {
  table {
    thead {
      th {
        color: #000000;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
        opacity: 1;
        &:first-child {
          padding-left: 17.5px;
        }
      }
    }
    tbody {
      tr {
        td {
          color: #000000;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 24px;
          padding-left: 0;
          &:first-child {
            padding-left: 17.5px;
          }
          button {
            &.btn-primary {
              height: 32px;
              color: #ffffff;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 14px;
              min-width: 110px;
              border-radius: 8px;
              background-color: #065ae4;
              margin-right: 12px;
            }
          }
          .pending {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            border: 1px solid rgba(0, 0, 0, 0.7);
            padding: 4px 11.5px;
            width: fit-content;
            opacity: 0.7;
            text-transform: uppercase;
            font-size: 11px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
            border-radius: 12px;
          }
          .processed {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            min-width: 85px;
            padding: 4px 11.5px;
            width: fit-content;
            border-radius: 12px;
            font-size: 11px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
            color: #ffffff;
            background-color: #009a52;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
.search-wrapper {
  border: 1px solid #b3b3b3;
  border-radius: 8px;
  background-color: #ffffff;
}
</style>
